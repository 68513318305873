import { Common } from '~/models/common';
import { Documents } from '~/models/documents';

const collection = 'documents';

export const documentsService = {
	fetch: async (): Promise<Documents.Model[]> => {
		const { getItems } = useDirectusItems();

		return await getItems<Documents.Model>({
			collection,
			params: {
				filter: {
					status: Common.Status.PUBLISHED,
				},
				fields: ['*', 'preview.*', 'file.*'],
			},
		});
	},
};
