<template lang="pug">
div(class='UiFile', :class='{ "UiFile--Loading": isLoading }', @click='downloadFile')
	slot
</template>

<script lang="ts" setup>
import { joinURL } from 'ufo';
import { File } from '~/models/file';

const { file } = defineProps<{
	file: File.Model;
}>();

const isLoading = ref<boolean>(false);

const {
	public: { siteAssets },
} = useRuntimeConfig();

const downloadFile = () => {
	if (file.filename_disk) {
		const filename = file.filename_download || file.filename_disk;
		const fileDownloadUrl = joinURL(siteAssets, file.filename_disk);

		const xhr = new XMLHttpRequest();
		xhr.responseType = 'blob';
		xhr.onload = () => {
			isLoading.value = true;

			const a = document.createElement('a');
			a.href = window.URL.createObjectURL(xhr.response);
			a.download = filename;
			a.style.display = 'none';
			document.body.appendChild(a);
			a.click();
			a.remove();

			isLoading.value = false;
		};
		xhr.open('GET', fileDownloadUrl);
		xhr.send();
	}
};
</script>

<style lang="less" scoped>
.UiFile {
	.cursor(pointer);
	.box(flex; auto; auto; none; center; space-between; center; nowrap row);
	.transition(all; 250ms; 'sine');

	& > * {
		.cursor(pointer);
	}

	& > *:not(:last-child) {
		padding: 0 1rem 0 0;
	}

	&--Loading {
		.opacity(0.5);
	}
}
</style>
