import { Documents } from '~/models/documents';
import { documentsService } from '~/services/documents';

export const useDocumentsState = () => ({
	documentsState: useState<Documents.Model[]>('documentsState', () => []),
	documentsLoaded: useState<boolean>('documentsLoaded', () => false),
	documentsPending: useState<boolean>('documentsPending', () => true),
});

export const useDocuments = () => {
	const { documentsState, documentsLoaded, documentsPending } = useDocumentsState();

	/**
	 * Fetch documents
	 */
	const fetchDocuments = async () => {
		if (documentsLoaded.value) return;

		documentsPending.value = true;

		documentsState.value = await documentsService.fetch();

		documentsLoaded.value = true;
		documentsPending.value = false;
	};

	/**
	 * Refresh documents
	 */
	const refreshDocuments = async () => {
		documentsLoaded.value = false;

		await fetchDocuments();
	};

	/**
	 * Documents list
	 */
	const documentsList = computed(() => documentsState.value.filter(document => document.show));

	/**
	 * Documents download list
	 */
	const documentsDownloadList = computed(() =>
		documentsState.value.filter(document => document.download)
	);

	return {
		documentsState,
		documentsLoaded,
		documentsPending,

		documentsList,
		documentsDownloadList,

		fetchDocuments,
		refreshDocuments,
	};
};
