<template lang="pug">
div(class='SliderDocuments')
	h2(v-if='variablesList.documentsHeader') {{ variablesList.documentsHeader }}
	div(v-if='variablesList.documentsDescription', class='HeaderDescription') {{ variablesList.documentsDescription }}

	UiCarousel(
		class='SliderDocuments--Carousel',
		:perView='greaterThan("tablet") ? 3 : greaterThan("mobileMedium") ? 2 : 1.4',
		:gap='greaterThan("tablet") ? "xl" : greaterThan("mobile") ? "md" : "sm"',
		ref='slider'
	)
		template(#default='{ carouselItemProps }')
			TransitionGroup(name='slider')
				UiCarouselItem(
					v-for='document in documentsList',
					:key='document.id',
					v-bind='carouselItemProps'
				)
					div(class='SliderDocuments--Carousel--Item')
						div(class='Document')
							UiImage(v-if='document.preview', :image='document.preview', width='350px', lazy)
						div(class='Name') {{ document.name }}

	UiDropdown(
		v-if='variablesList.documentsDownload && greaterThan("mobileWide")',
		class='DownloadDocuments',
		theme='select'
	)
		template(#label)
			div(class='DownloadDocuments--PdfIcon')
			div(class='DownloadDocuments--Dropdown')
				span {{ variablesList.documentsDownload }}
		template(#default)
			div(class='DropdownOptions')
				template(v-for='document in documentsDownloadList', :key='document.id')
					div(class='DropdownOptions--Option')
						UiFile(:file='document.file')
							label {{ document.name }}
							UiIcon(name='system/pdf', size='xl')
</template>

<script lang="ts" setup>
import { UiCarousel } from '#components';

/**
 * Define refs
 */
const slider = ref<InstanceType<typeof UiCarousel> | null>(null);

/**
 * Define hooks
 */
const { greaterThan } = usePlatform();
const { documentsList, documentsDownloadList, fetchDocuments } = useDocuments();
const { variablesList, fetchVariables } = useVariables();

await fetchDocuments();
await fetchVariables();
</script>

<style lang="less" scoped>
.SliderDocuments {
	.box(grid; 100%; auto; none; 1rem; auto; 100%; center; start);

	&--Carousel {
		.grid(3 4, 1 span);
		.box(block; 100%; auto; none);

		&--Item {
			.box(grid; 100%; 100%; none; 0.5rem; auto 1fr; auto; stretch; center);
			.border-radius(@BorderRadiusSmall);
			& > .Document {
				.grid(1 span);
				& > img {
					object-fit: contain;
					.grid-aspect(3.5; 5);
					.box(block; 100%; 100%);
					.border-radius(@BorderRadius);
				}
			}
			& > .Name {
				max-width: 20rem;
				.grid(1 span);
				.text(@ColorBase; 1.25em 1rem @regular; left italic);
			}
		}
	}

	.DownloadDocuments {
		max-width: 35rem;
		margin: 0 auto;
		.cursor(pointer);
		.box(grid; 100%; auto; none; 1rem; 1fr; 4.6875rem 1fr; center; start);
		& > .DownloadDocuments--PdfIcon {
			.grid(1 span);
			.box(4.6875rem; 4.6875rem; url('@/images/pdf.png'));
		}
		&--Dropdown {
			min-height: 4.6875rem;
			padding: 0.5rem 2rem;
			border: 2px @ColorPrimary solid;
			.grid(1 span);
			.box(flex; 100%; auto; none; center; center; center; nowrap row);
			.text(@ColorBase; 1.25em 1.125rem @semibold);
			.border-radius(@BorderRadius);
			.transition(all; 250ms; 'sine');
		}

		&:hover,
		&.v-popper--shown {
			& > .DownloadDocuments--Dropdown {
				.background(@ColorPrimary);
				.text(@ColorWhite);
			}
		}
	}

	@media all and (min-width: @tablet) {
		padding: 5rem;
		.relative(1; hidden);
		.border-radius(@BorderRadiusLarge);
		.box(grid; 100%; auto; @ColorWhite; 2rem; auto; 100%; center; center);

		&--Carousel {
			padding: 1rem 0;
			&--Item {
				padding: 0 0 1rem 0;
			}
		}

		&:before {
			content: '';
			.absolute(1; none; none none 0 0);
			.box(block; 10.375rem; 17.0625rem; url('@/images/documents-bg.png'));
		}
	}
}
</style>
